import React, { useState, useEffect } from "react";
import { ILotFilterValueModel } from "../../Common/types";


interface ILotFilter {
    FilterName: string,
    Value: String
}

interface IPropsType {
    lotFilters: Array<ILotFilter>,
    lotDescription: string
}
function AboutLot(props: IPropsType) {

    const [data, setData] = useState(props.lotFilters['Основные характеристики']);

    const [lotDescription, setLotDescription] = useState('');
    const [splitedArr, setSplitedArr] = useState([]);
    const [isShowMoreButton, setIsShowMoreButton] = useState(false);
    const [isShowMore, setIsShowMore] = useState(false);

    useEffect(() => {
        if (props.lotDescription) {
            const splitDescription = props.lotDescription.split(' ');
            if (splitDescription.length > 40) {
                setSplitedArr(splitDescription);
                setIsShowMoreButton(true);
                setLotDescription(splitDescription.slice(0, 40).join(' '));
            }
        }
    }, [])

    const showMore = () => {
        if (isShowMore) {
            setLotDescription(splitedArr.slice(0, 40).join(' '));
            setIsShowMore(false);
        } else {
            setLotDescription(splitedArr.join(' '));
            setIsShowMore(true);
        }
    }

    return (
        <div className="aboutLot detailsWrapper">
            <div className="dataTable">
                {data ? (
                    data.map((item) => {
                        return (
                            <div className="dataItem">
                                <span className="defaultDataName">{item.FilterName}:</span>
                                <p className="defaultDataValue">{item.Value}</p>
                            </div>
                        )
                    })
                ) : (
                    <p className="errorText">Данные отсутствуют</p>
                )}
            </div>

            <div className="description">
                <span className="defaultDataName">Дополнительная <br /> информация:</span>
                <div className="descriptionText">
                    {/*<p>{props.lotDescription}</p>*/}
                    <p>
                        {lotDescription}
                    </p>
                    {isShowMoreButton &&
                        <span onClick={showMore}>
                            {isShowMore ? 'Скрыть' : 'Показать больше' }
                        </span>    
                    }
                </div>
            </div>
        </div>
    )
}


export default AboutLot;