import React, { useState, useEffect } from "react";
import LotHead from "./LotHead";
import LotDetails from "./LotDetails";
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
//import "../../../css/lotPage.css";
import fetcher, { fetcherGET } from "../../Common/Fetch";
import OrganizerActions from "./OrganizerActions";
import YandexMap from "../../Shared/YandexMap";
import OtherLots from "./OtherLots";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { ILotModel, IDocumentModel, LotPriceHistoryModel } from "../../Common/types";

export interface IAboutTrade {
    LotState: string,
    TradeType: string,
    DepositRules: string,
    LotTradePeriodStart: string,
    LotTradePeriodEnd: string,
    LotApplicationPeriodStart: string,
    LotApplicationPeriodEnd: string
}

export interface ILotHeadInfo {
    Price: number,
    LotState: string,
    CountBets: number,
    TradeType: string,
    StepValue: number,
    LotTradePeriodEnd: string,
    Name: string
}

export interface IPicture {
    FullUrl: string
}

interface IPropsType {
    lotId: number,
    theme: string
}
 
function LotPage(props: IPropsType) {
    const lotId = props.lotId;

    const [lotDetails, setLotDetails] = useState<ILotModel>();

    const [aboutTrade, setAboutTrade] = useState<IAboutTrade>();
    const [documents, setDocuments] = useState<IDocumentModel>();
    const [lotHeadInfo, setLotHeadInfo] = useState<ILotHeadInfo>();
    const [pictures, setPictures] = useState<Array<IPicture>>();
    const [fullAddress, setFullAddress] = useState(null);

    const [error, setError] = useState('');

    //Собираем всю информацию о лоте(ILotModel)
    useEffect(() => {
        console.log('getLotDetails')
        fetcher("/TradeLot/ViewLot2?id=" + lotId, { method: 'GET' })
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                setLotDetails(result);
                setFullAddress(`${result.Address.StateProvince}, ${result.Address.CityName}`);

                setPictures(result.Pictures);

                setLotHeadInfo(
                    {
                        Price: result.CurrentPrice,
                        LotState: result.LotTradeStateDescription,
                        CountBets: result.CountBets,
                        TradeType: result.TradeTypeDescription,
                        StepValue: result.StepValue,
                        LotTradePeriodEnd: result.LotTradePeriodEnd,
                        Name: result.Name
                    }
                )

                setAboutTrade(
                    {
                        LotState: result.LotTradeStateDescription,
                        TradeType: result.TradeTypeDescription,
                        DepositRules: result.DepositRules,
                        LotTradePeriodStart: result.LotTradePeriodStart,
                        LotTradePeriodEnd: result.LotTradePeriodEnd,
                        LotApplicationPeriodStart: result.LotApplicationPeriodStart,
                        LotApplicationPeriodEnd: result.LotApplicationPeriodEnd
                    }
                )

                setDocuments(result.Documents);
            })
            .catch((error) => {
                console.error('GetLotDetails', error);
                setError('Произошла ошибка');
            });
    }, [])

    useEffect(() => {
        console.log("lotDetails обновлено:", lotDetails);
    }, [lotDetails]);

    useEffect(() => {
        if (!lotDetails) return;

        const hubConnection = new HubConnectionBuilder()
            .withUrl(`/betting?lotId=${lotId}`)
            .withAutomaticReconnect()
            .build();

        hubConnection.on('NewBet', (obj) => {
            if (obj.Price && obj.BettorName) {
                console.log("Сделанная ставка идёт в веб-сокет", obj);

                setLotDetails((prevDetails) => {
                    if (!prevDetails) return prevDetails;

                    return {
                        ...prevDetails,
                        CurrentPrice: obj.Price,
                        CountBets: obj.TotalBets,
                        LotPriceHistoryModel: [...prevDetails.LotPriceHistoryModel, obj],
                    };
                });

                setLotHeadInfo((prevHeadInfo) => {
                    if (!prevHeadInfo) return prevHeadInfo;
                    return {
                        ...prevHeadInfo,
                        Price: obj.Price,
                        CountBets: obj.TotalBets
                    };
                });
            }
        });

        hubConnection.start()
            .then(() => console.log('SignalR Connected'))
            .catch((err) => console.error('SignalR Connection Error: ', err));

        return () => {
            hubConnection.stop();
        };
    }, [lotId, lotDetails]);

    const findCity = (cityId) => {
        fetcherGET(`/Address/GetCitiesByStateId?${lotDetails.Address.StateProvinceId}`, (result) => {
            setFullAddress(`${result.Name}`);
        });
    }


    return (
        <div className="lotPage">
            {lotDetails ? (
                <>
                    {lotHeadInfo ? (
                        <LotHead lotHeadInfo={lotHeadInfo} pictures={pictures} lotId={lotId} lotDetails={lotDetails} theme={props.theme} />
                    ) : (
                        <p>Загружаем информацию о лоте...</p>
                    )}
                    {lotDetails.IsOrganizer &&
                        <OrganizerActions lotDetails={lotDetails} lotId={props.lotId} />
                    }
                    <LotDetails
                        lotDetails={lotDetails}
                        aboutTrade={aboutTrade}
                        documents={documents}
                        lotId={lotId}
                    />

                    <div className="yandexMapWrapper">
                        <h4 className="px30PinkHead">Расположение</h4>
                        {props.theme !== "MitraArt" && <YandexMap address={lotDetails.Address.FullAddress} />}
                        {props.theme === "MitraArt" && <YandexMap address={fullAddress} />}
                    </div>

                    <OtherLots />
                </>
            ): (
                <p className="errorText">{error ? error : 'Идет загрузка..'}</p>
            )}
        </div>
    )
}


export default LotPage;